.main{
    width: 900px;
}

.smi button{
    width: 100%;
}

.break{
    margin: 15px;
    height: 50px;
    /* background-color: red; */
}

.divider{
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .main{
        width: 100%;
    }
    .break{
        margin: 15px;
        height: 30px;
    }
}