.download-btn {
    background-color: #4CAF50;
    border: none;
    padding: 5px 8px;
    margin: 0 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
  }
  .download-btn > a {
    text-decoration: none;
    color: inherit;
  }
  .download-btn:hover {
    background-color: #3e8e41;
  }