body {
  background-color: #ccfae3;
}

.App{
  display: grid;
  margin: 1vw auto;
  justify-items: center;
}

@media screen and (max-width: 768px) {
  .App {
    margin: 2vw auto;
    grid-template-rows: 90vw;
  }
  
}