.social-icons {
    display: grid;
    gap: 30px;
    grid-template-columns: 50px 50px 50px 50px;
    justify-content: center;
    justify-items: center;
}

.social-header {
    margin: 0 0 15px 0;
    text-align: center;
    font-size: large;
    font-style: italic;
    letter-spacing: 1.2px;
}